import React, { ReactNode } from "react";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import "./Slider.css";
import { TOptionsEvents } from "keen-slider";

const Slider = ({
  options,
  children,
}: {
  options: TOptionsEvents;
  children?: ReactNode;
}) => {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [sliderRef, slider] = useKeenSlider(
    options !== undefined
      ? {
          ...options,
          slideChanged(s) {
            setCurrentSlide(s.details().relativeSlide);
          },
        }
      : {
          initial: 0,
          spacing: 10,
          breakpoints: {
            "(min-width: 768px)": {
              slidesPerView: 2,
              mode: "free-snap",
            },
            "(min-width: 1200px)": {
              slidesPerView: 3,
              mode: "free-snap",
            },
          },
          slideChanged(s) {
            setCurrentSlide(s.details().relativeSlide);
          },
        }
  );

  return (
    <div className="navigation-wrapper">
      <div ref={sliderRef} className="keen-slider">
        {children}
      </div>
      {slider && (
        <>
          <ArrowLeft
            onClick={(e) => e.stopPropagation() || slider.prev()}
            disabled={currentSlide === 0}
          />
          <ArrowRight
            onClick={(e) => e.stopPropagation() || slider.next()}
            disabled={currentSlide === slider.details().size - 1}
          />
        </>
      )}
    </div>
  );
};

function ArrowLeft(props) {
  const disabled = props.disabled ? " arrow--disabled" : "";
  return (
    <svg
      {...props}
      onClick={props.onClick}
      className={"arrow arrow--left" + disabled}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="19.5" stroke="#2D2D2D" />
      <path d="M22 26.6666L16 20.3333L22 14" stroke="#2D2D2D" />
    </svg>
  );
}

function ArrowRight(props) {
  const disabled = props.disabled ? " arrow--disabled" : "";
  return (
    <svg
      {...props}
      onClick={props.onClick}
      className={"arrow arrow--right" + disabled}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="19.5" stroke="#2D2D2D" />
      <path d="M18 14L24 20.3333L18 26.6667" stroke="#2D2D2D" />
    </svg>
  );
}

export default Slider;
