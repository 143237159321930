import React from "react";

import { carousel_container, carousel, slide } from "./WorkingWith.module.css";
import Slider from "../Slider/Slider";
import "keen-slider/keen-slider.min.css";

const WorkingWith = ({ images, heading }) => {
  return (
    <div className={carousel_container}>
      <h2>{heading}</h2>

      <div className={carousel}>
        <Slider
          options={{
            initial: 0,
            loop: true,
            breakpoints: {
              "(min-width: 768px)": {
                slidesPerView: 2,
                mode: "free-snap",
              },
              "(min-width: 1200px)": {
                slidesPerView: 3,
                mode: "free-snap",
              },
            },
          }}
        >
          {images.map((image, index) => (
            <div key={index} className={`keen-slider__slide ${slide}`}>
              {image}
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default WorkingWith;
